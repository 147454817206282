<template>
  <nav class="bg-primary text-white font-spaceGrotesk fixed top-0 w-full z-50">
    <div class="container max-w-5xl mx-auto px-6 py-3 flex items-center justify-between">
      <div>
        <!-- Language Selector -->
        <div v-if="showLanguageSelector" class="language-selector">
          <select v-model="selectedLanguage" class="text-black text-sm" @change="changeLanguage">
            <option value="en">EN</option>
            <option value="es">ES</option>
            <option value="xx">XX</option>
          </select>
        </div>
      </div>
      <div>
        <nuxt-link class="text-white text-3xl font-semibold uppercase hover:text-secondary md:text-4xl" to="/">
          <img src="/assets/images/VaultGP_Blanco-verde.svg" alt="Logo" class="h-8" style="image-rendering: crisp-edges; width: auto;"> 
        </nuxt-link>
      </div>
      <div class="relative">
        <div class="flex items-center">
          <button data-dropdown-toggle="dropdownId" ref="menuButton" @click="toggleProfileMenu" class="focus:outline-none">
            <img v-if="picture" referrerpolicy="no-referrer" :src="picture"  alt="Profile icon" class="border-white border-2 object-contain h-8 rounded-full">
            <img v-else src="/profile.svg" alt="Profile icon" class="object-contain" />
          </button>
          <div  v-if="isProfileMenuOpen" ref="menuDropdown"
               class="absolute right-0 top-7 mt-2 w-36 bg-white rounded-md overflow-hidden shadow-xl z-10">
               <nuxt-link @click="toggleProfileMenu" class="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-500 hover:text-white" to="/user-profile">{{ $t('Profile') }}</nuxt-link>
               <nuxt-link v-if="!publicKey" @click="toggleProfileMenu" class="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-500 hover:text-white" to="/generate-key">{{ $t('generate-key') }}</nuxt-link>
               <a href="https://vaultgp.com/faqs/" class="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-500 hover:text-white" to="/user-profile">{{ $t('need-help-link') }}</a>
            <nuxt-link @click="logout" class="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-500 hover:text-white" to="/">{{ $t('logout') }}</nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { useAuthenticationStore } from '@/store/modules/authentication';

const { picture, publicKey } = useAuthenticationStore();
const { locale } = useI18n();
const selectedLanguage = ref(locale.value); // Default language

const config = useRuntimeConfig();
const showLanguageSelector = ref(config.public.ENV === 'staging' || config.public.ENV === 'development');

const changeLanguage = () => {
  locale.value = selectedLanguage.value;
};

let isProfileMenuOpen = ref(false);
let menuButton = ref(null);
let menuDropdown = ref(null);

const toggleProfileMenu = () => {
  isProfileMenuOpen.value = !isProfileMenuOpen.value;
}

const handleClickOutside = (event) => {
  if (menuButton.value && !menuButton.value.contains(event.target) && menuDropdown.value && !menuDropdown.value.contains(event.target)) {
    isProfileMenuOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const logout = async () => {
  toggleProfileMenu();
  navigateTo('/logout');
}
</script>